exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-jsx": () => import("./../../../src/pages/blog/index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-coming-soon-jsx": () => import("./../../../src/pages/ComingSoon.jsx" /* webpackChunkName: "component---src-pages-coming-soon-jsx" */),
  "component---src-pages-company-index-jsx": () => import("./../../../src/pages/company/index.jsx" /* webpackChunkName: "component---src-pages-company-index-jsx" */),
  "component---src-pages-contact-company-index-jsx": () => import("./../../../src/pages/contact-company/index.jsx" /* webpackChunkName: "component---src-pages-contact-company-index-jsx" */),
  "component---src-pages-deleteaccount-index-jsx": () => import("./../../../src/pages/deleteaccount/index.jsx" /* webpackChunkName: "component---src-pages-deleteaccount-index-jsx" */),
  "component---src-pages-deletedata-index-jsx": () => import("./../../../src/pages/deletedata/index.jsx" /* webpackChunkName: "component---src-pages-deletedata-index-jsx" */),
  "component---src-pages-ev-charging-station-franchise-business-index-jsx": () => import("./../../../src/pages/EV-charging-station-franchise-business/index.jsx" /* webpackChunkName: "component---src-pages-ev-charging-station-franchise-business-index-jsx" */),
  "component---src-pages-ev-charging-station-franchise-business-opportunity-index-jsx": () => import("./../../../src/pages/EV-charging-station-franchise-business-opportunity/index.jsx" /* webpackChunkName: "component---src-pages-ev-charging-station-franchise-business-opportunity-index-jsx" */),
  "component---src-pages-ev-charging-station-franchise-opportunity-index-jsx": () => import("./../../../src/pages/EV-charging-station-franchise-opportunity/index.jsx" /* webpackChunkName: "component---src-pages-ev-charging-station-franchise-opportunity-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-install-ev-charging-station-hotels-resorts-index-jsx": () => import("./../../../src/pages/install-ev-charging-station-hotels-resorts/index.jsx" /* webpackChunkName: "component---src-pages-install-ev-charging-station-hotels-resorts-index-jsx" */),
  "component---src-pages-partners-ev-charging-station-business-oppurtunity-index-jsx": () => import("./../../../src/pages/partners/ev-charging-station-business-oppurtunity/index.jsx" /* webpackChunkName: "component---src-pages-partners-ev-charging-station-business-oppurtunity-index-jsx" */),
  "component---src-pages-partners-invest-in-ev-charging-business-index-jsx": () => import("./../../../src/pages/partners/invest-in-ev-charging-business/index.jsx" /* webpackChunkName: "component---src-pages-partners-invest-in-ev-charging-business-index-jsx" */),
  "component---src-pages-partners-setup-ev-charging-station-index-jsx": () => import("./../../../src/pages/partners/setup-ev-charging-station/index.jsx" /* webpackChunkName: "component---src-pages-partners-setup-ev-charging-station-index-jsx" */),
  "component---src-pages-privacypolicy-index-jsx": () => import("./../../../src/pages/privacypolicy/index.jsx" /* webpackChunkName: "component---src-pages-privacypolicy-index-jsx" */),
  "component---src-pages-setup-ev-charging-station-restaurants-cafes-index-jsx": () => import("./../../../src/pages/setup-ev-charging-station-restaurants-cafes/index.jsx" /* webpackChunkName: "component---src-pages-setup-ev-charging-station-restaurants-cafes-index-jsx" */),
  "component---src-pages-termsandconditions-index-js": () => import("./../../../src/pages/termsandconditions/index.js" /* webpackChunkName: "component---src-pages-termsandconditions-index-js" */),
  "component---src-templates-blogpost-js": () => import("./../../../src/templates/blogpost.js" /* webpackChunkName: "component---src-templates-blogpost-js" */)
}

